<template>
    <InputWithAction
    :icon="'note_add'"
    :showActionButton="true"
    :isLoading="loadingNewBrand"
    :placeHolderText="$t('bvs.create_custom_brand')"
    @do-something="createNewBrandCustom($event)" />
</template>

<script>
import { socialMediaService } from '@/_services'

export default {
  name: 'InputCreateBrand',
  components: {
    InputWithAction: () => import('./InputWithAction.vue')
  },
  data () {
    return {
      loadingNewBrand: false
    }
  },
  computed: {
    account () {
      return this.$store.getters['account/getAccount']
    }
  },
  methods: {
    async createNewBrandCustom (nameCustom) {
      if (nameCustom.length > 0) {
        const toSend = { name: nameCustom, metadata: {} }
        this.loadingNewBrand = true
        const res = await socialMediaService.createBrands([toSend])
        this.loadingNewBrand = false
        if (res.message === 'Limit of terms reached') {
          this.$store.commit('alerts/alert', {
            message: this.$t('bvs.search_not_found'),
            position: 'bottomRight',
            showAlert: true
          })
        } else {
          this.$store.commit('account/updateBrandsTracking', res.brandsTracking)
          this.$store.commit('alerts/alert', {
            message: this.$t('bvs.create_success'),
            position: 'bottomRight',
            showAlert: true
          })
          this.$emit('scroll-to-brand')
        }
      }
    }
  }
}
</script>
<style lang="scss">
</style>
